import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Header from "../components/Header";
import Quote from "../components/Quote";
import Layout, { PageLayout } from "../components/Layout";
import { MaybeStickyTitle, Title, TitleDescription } from "../components/Titles";
import Nest from "../components/Nest";
import { ImageItem, Contain, LinksLayout } from "../components/ImageContainer";
import { Feedback, Calendar } from "../components/Social";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => {
  return <PageLayout>
      <Nest>
        <Header />
        <Quote content="There is but one true way, however, of getting out of any position we may be in, and that is, to do the work of it so well that we grow fit for a better." source="George MacDonald" />
        <Layout left={<MaybeStickyTitle>
              <Title>User Guide</Title>
              <TitleDescription>
                Here is a high level guide to understand my working style and
                working with me effectively.
              </TitleDescription>
            </MaybeStickyTitle>} right={<div style={{
        maxWidth: "664px"
      }}>
              <Contain>
                <LinksLayout>
                  <ImageItem href="https://merrickchristensen.com/feedback" image={<Feedback height={160} />} description="Send Me Feedback" />
                  <ImageItem href="https://merrickchristensen.com/meeting" image={<Calendar height={160} />} description="Schedule Time" />
                </LinksLayout>
              </Contain>
              <div style={{
          paddingTop: "24px"
        }}>{children}</div>
            </div>} />
      </Nest>
    </PageLayout>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Feedback Style`}</h2>
    <p>{`Please feel free to
`}<a parentName="p" {...{
        "href": "https://merrickchristensen.com/feedback"
      }}>{`leave me feedback`}</a>{`, reveal my
blindspots to me, help me improve. You can even do it anonymously, its just a
Google Form. If you'd like to talk about something in real time please
`}<a parentName="p" {...{
        "href": "https://merrickchristensen.com/meeting"
      }}>{`schedule some shared time with me`}</a>{`.`}</p>
    <ul>
      <li parentName="ul">{`I want any and all feedback and I’d rather risk getting feedback in painful
ways than to not get the feedback because you were unsure about how to provide
it. It’s easier if we have a relationship where I trust you and know you have
my best interest at heart but I’ll take feedback from people who don’t have my
best interest at heart and assess it in its own right if it brings me closer
to reality. That being said, difficult conversations are far more grounded for
me in person or over a video call.`}</li>
      <li parentName="ul">{`I will absolutely take your feedback to heart (look, I'm sensitive ok?). If
you give me feedback I will take it very seriously and adapt. Consequently I
ask that you are deliberate and sincere when giving me feedback.`}</li>
    </ul>
    <h1>{`Style`}</h1>
    <ul>
      <li parentName="ul"><strong parentName="li">{`I care a lot.`}</strong>{` Often because I am so emotionally invested in what I do for
work I can sometimes get discouraged or frustrated if things aren’t going
well. It also means it is really hard for me not to provide feedback if I
think things can be better. This feeling is particularly bad when I feel like
I have little to no control to influence or change things. I do best if I’m
heard and given a domain to truly own.`}</li>
      <li parentName="ul"><strong parentName="li">{`I really value being genuine and honest.`}</strong>{` So much so that sometimes I can
come across more negative than I intend to because I’d rather be transparent
than artificially positive. I’d also prefer to share in someone’s honest
suffering than have my perception attempted to be managed. If I’m talking ad
nauseum about the same things or zapping your energy, please tell me.`}</li>
      <li parentName="ul"><strong parentName="li">{`I want meaningful friendships not distant professional relationships.`}</strong>{`
Somes I can be a bit too personal for some people’s taste because by default I
want a meaningful friendship and connection with everyone I meet. If I ever
make you uncomfortable please feel free to share that you’d prefer to keep
things professional.`}</li>
      <li parentName="ul"><strong parentName="li">{`I want mastery.`}</strong>{` Sometimes this can come across a bit idealistic or hard to
please. I don’t do very well in tasks or roles that aren’t challenging for me.
I’d much prefer the pain of challenge than the pain (or comfort) of boredom. I
really need the ability to experiment and control how I work in the form of
tools, environments & techniques.`}</li>
      <li parentName="ul"><strong parentName="li">{`I finish what I start.`}</strong>{` It is very important for me to see a task all the
way through to end users before moving to the next one. I call this
optimization for flow efficiency. I don’t believe working on many things
concurrently is effective.`}</li>
    </ul>
    <h2>{`Values`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Autonomy.`}</strong>{` The ability to experiment and control how I work in the form of
tools, environments & techniques.`}</li>
      <li parentName="ul"><strong parentName="li">{`Mastery.`}</strong>{` I will not work on projects in which I am not growing personally
in one dimension or another.`}</li>
      <li parentName="ul"><strong parentName="li">{`Impact.`}</strong>{` I must have the ability to be true to myself and pursue making my
biggest impact with intensity. Not tempered and mitigated into a particular
role.`}</li>
      <li parentName="ul"><strong parentName="li">{`Reality.`}</strong>{` I’ll choose a painful reality over a comfortable delusion 100% of
the time.`}</li>
      <li parentName="ul"><strong parentName="li">{`Action.`}</strong>{` I have a huge bias towards action. I believe reality is the great
iconoclast. The only way to check your model for reality is to test it with
action. The sooner you learn you’re wrong, the better.`}</li>
      <li parentName="ul"><strong parentName="li">{`Authenticity, compassion & vulnerability.`}</strong>{` I want to work in an environment
that makes me feel like a human to be protected, not a resource to be
exploited. Somewhere that trusts me. A place that is externally competitive
(not internally.) Incentive structures shouldn't encourage politics.`}</li>
      <li parentName="ul"><strong parentName="li">{`Craftsmanship.`}</strong>{` Not doing my best work is a disgrace to the privilege I
have of doing it.`}</li>
      <li parentName="ul"><strong parentName="li">{`Growth.`}</strong>{` Please. Teach. Me.`}</li>
    </ul>
    <h2>{`Repulsions`}</h2>
    <ul>
      <li parentName="ul">{`Arrogance.`}</li>
      <li parentName="ul">{`Meanness.`}</li>
      <li parentName="ul">{`When people phone it in.`}</li>
      <li parentName="ul">{`When someone won't lead, follow or get out of the way. Complaining from the
sidelines.`}</li>
      <li parentName="ul">{`When a company or someone else treats me as a resource to leverage and not an
agent pursuing a shared goal.`}</li>
      <li parentName="ul">{`Optimization for personal advancement over the collective goals of the
company.`}</li>
      <li parentName="ul">{`Generalizations. It is tempting for people to generalize their experience,
reality is often far more nuanced and I struggle when the pursuit of reality
is frustrated by someones false generalizations. Some examples include, rigid
code style guides, solving problems and processes in "theory", etc.`}</li>
      <li parentName="ul">{`Process for process sake. Doing things for false comfort. Reality is uncertain
and difficult to predicit, that is an uncomfortable reality. I’d rather
embrace the uncomfortable reality and adjust process and tooling around it to
optimize for the desired outcome. Unless it is truely the right tool for the
context I find ready-made practices like burn down charts, distant deadlines,
velocity measurements, task voting, etc worse than a waste of time.`}</li>
    </ul>
    <h2>{`Decision Making Style`}</h2>
    <ul>
      <li parentName="ul">{`Start with `}<strong parentName="li">{`my`}</strong>{` values and determine a desired outcome. Find out what is
true. Determine what to do about it. Bias towards action and leverage the
results to correct my world view about what is true. I tend to rely on
probability, data, risk/reward analysis, etc… Success in a metric I don't
value would be worse than failure in something I do.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      